import {
    setTerminationType,
    setTerminationCategory,
    setTerminationRequestDate,
    setTerminationFullName,
    setTerminationAmount,
    setTerminationPaymentDate,
    setTerminationSigningDate,
    setTerminationNotes,
    setFullName,
    setCategory,
    setRequestDate,
    setModificationsToMake,
    setPlayerOrCoach,
    setRepresentationFullName,
    setRepresentationNationality,
    setRepresentationAddress,
    setRepresentationRfcOrTaxId,
    setRepresentationPassportNumber,
    setRepresentationFifaRegistrationNumber,
    setRepresentationConsideration,
    setRepresentationOtherConditions,
    setRepresentationBankDetails,
    setRepresentationJurisdiction,
    setEmploymentPosition,
    setEmploymentContractType,
    setEmploymentDirectedTo,
    setEmploymentCategory,
    setEmploymentRequestDate,
    setEmploymentFullName,
    setEmploymentNationality,
    setEmploymentDateOfBirth,
    setEmploymentAddress,
    setEmploymentMaritalStatus,
    setEmploymentEmail,
    setEmploymentFreeAgentOrTransfer,
    setEmploymentRelocation,
    setEmploymentContractDuration,
    setEmploymentAnnualSalary,
    setEmploymentFirstPaymentDate,
    setEmploymentLastPaymentDate,
    setEmploymentPaymentFrequency,
    setEmploymentSportsBonuses,
    setEmploymentOtherBenefits,
    setEmploymentPlayerTerminationClause,
    setEmploymentClubTerminationClause,
    setEmploymentJurisdiction,
    setEmploymentSigningDate,
    setTransferClubName,
    setTransferClubRepresentative,
    setTransferEmail,
    setTransferTransferType,
    setTransferValidity,
    setTransferFreeOrCost,
    setTransferPurchaseOption,
    setTransferSolidarityWithholding,
    setTransferOtherConditions
} from "../slices";
import dayjs from 'dayjs'

const setTerminationForm = (data, dispatch) => {
    const requestDateString = dayjs(data.request_date);
    const paymentDate = dayjs(data.payment_date);
    const signingDate = dayjs(data.signing_date);

    dispatch(setTerminationType(data.termination_type));
    dispatch(setTerminationCategory(data.category));
    dispatch(setTerminationRequestDate(requestDateString));
    dispatch(setTerminationFullName(data.full_name));
    dispatch(setTerminationAmount(data.termination_amount));
    dispatch(setTerminationPaymentDate(paymentDate));
    dispatch(setTerminationSigningDate(signingDate));
    dispatch(setTerminationNotes(data.notes));
}

const setModification = (data, dispatch) => {
    const requestDate = dayjs(data.request_date);

    dispatch(setFullName(data.full_name));
    dispatch(setCategory(data.category));
    dispatch(setRequestDate(requestDate));
    dispatch(setModificationsToMake(data.modifications_to_make));
    dispatch(setPlayerOrCoach(data.agreement_type))
}

const setRepresentation = (data, dispatch) => {
    dispatch(setRepresentationFullName(data.full_name));
    dispatch(setRepresentationNationality(data.nationality));
    dispatch(setRepresentationAddress(data.address));
    dispatch(setRepresentationRfcOrTaxId(data.rfc_or_tax_id));
    dispatch(setRepresentationPassportNumber(data.passport_number));
    dispatch(setRepresentationFifaRegistrationNumber(data.fifa_registration_number));
    dispatch(setRepresentationConsideration(data.consideration));
    dispatch(setRepresentationOtherConditions(data.other_conditions));
    dispatch(setRepresentationBankDetails(data.bank_details));
    dispatch(setRepresentationJurisdiction(data.jurisdiction))
}

const setEmployment = (data, dispatch) => {
    const signingDate = dayjs(data.signing_date);
    const firstPaymentDate = dayjs(data.first_payment_date);
    const lastPaymentDate = dayjs(data.last_payment_date);
    const dateOfBirth = dayjs(data.date_of_birth);
    const requestDate = dayjs(data.request_date);

    dispatch(setEmploymentPosition(data.position));
    dispatch(setEmploymentContractType(data.contract_type));
    dispatch(setEmploymentDirectedTo(data.directed_to));
    dispatch(setEmploymentCategory(data.category));
    dispatch(setEmploymentRequestDate(requestDate));
    dispatch(setEmploymentFullName(data.full_name));
    dispatch(setEmploymentNationality(data.nationality));
    dispatch(setEmploymentDateOfBirth(dateOfBirth));
    dispatch(setEmploymentAddress(data.address));
    dispatch(setEmploymentMaritalStatus(data.marital_status));
    dispatch(setEmploymentEmail(data.email));
    dispatch(setEmploymentFreeAgentOrTransfer(data.free_agent_or_transfer));
    dispatch(setEmploymentRelocation(data.relocation));
    dispatch(setEmploymentContractDuration(data.contract_duration));
    dispatch(setEmploymentAnnualSalary(data.annual_salary));
    dispatch(setEmploymentFirstPaymentDate(firstPaymentDate));
    dispatch(setEmploymentLastPaymentDate(lastPaymentDate));
    dispatch(setEmploymentPaymentFrequency(data.payment_frequency));
    dispatch(setEmploymentSportsBonuses(data.sports_bonuses));
    dispatch(setEmploymentOtherBenefits(data.other_benefits));
    dispatch(setEmploymentPlayerTerminationClause(data.player_termination_clause));
    dispatch(setEmploymentClubTerminationClause(data.club_termination_clause));
    dispatch(setEmploymentJurisdiction(data.jurisdiction));
    dispatch(setEmploymentSigningDate(signingDate))
}

const setTransfer = (data, dispatch) => {
    dispatch(setTransferClubName(data.club_name));
    dispatch(setTransferClubRepresentative(data.club_representative));
    dispatch(setTransferEmail(data.email));
    dispatch(setTransferTransferType(data.transfer_type));
    dispatch(setTransferValidity(data.validity));
    dispatch(setTransferFreeOrCost(data.free_or_cost));
    dispatch(setTransferPurchaseOption(data.purchase_option));
    dispatch(setTransferSolidarityWithholding(data.solidarity_withholding));
    dispatch(setTransferOtherConditions(data.other_conditions));
}

export const intoDataForm = (data, type, dispatch) => {
    const typesContracts = {
        "transfer": setTransfer,
        "employment": setEmployment,
        "representation": setRepresentation,
        "modification": setModification,
        "termination": setTerminationForm,
    }
    
    typesContracts[type](data, dispatch);
    return true
}