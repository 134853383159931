import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import { fetchWrapper } from "../services/api";

const ContractHistoryComponent = ({ contractId, onShowToast }) => {
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [dataHistory, setDataHistory] = useState([{}]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    handleGetHistory();
  }, [contractId]);

  const handleGetHistory = async () => {
    setLoadingHistory(true);
    fetchWrapper
      .get(`/contracts/${contractId}/contract_history/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDataHistory(response.data);
        setLoadingHistory(false);
      })
      .catch((error) => {
        console.error("Error al obtener el historial del contrato:", error);
        onShowToast(
          "Error",
          "Ocurrio un error al traer el historial." +
            error.response.data.message,
          "error"
        );
      });
  };

  if (loadingHistory) {
    return <div>Cargando historial...</div>;
  }

  return (
    <TableContainer component={Paper} color="#fff">
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "1em",
                textAlign: "center",
              }}
            >
              Usuario
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "1em",
                textAlign: "center",
              }}
            >
              Acción
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "1em",
                textAlign: "center",
              }}
            >
              Fecha
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "1em",
                textAlign: "center",
              }}
            >
              Razón
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataHistory.map((historyItem) => (
            <TableRow key={historyItem.id}>
              <TableCell align="center">{historyItem.user}</TableCell>
              <TableCell align="center">{historyItem.action}</TableCell>
              <TableCell align="center">
                {moment
                  .utc(historyItem.action_date)
                  .tz("America/Mexico_City")
                  .locale("es")
                  .format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
              </TableCell>
              <TableCell align="center">{historyItem.reason}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ContractHistoryComponent.propTypes = {
  contractId: PropTypes.number.isRequired,
  onShowToast: PropTypes.func.isRequired,
};

export default ContractHistoryComponent;
