import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { PropTypes } from "prop-types";

const DialogComponent = ({ open, onClose, children, title }) => {
  return (
    <ThemeProvider theme={createTheme()}>
      <Dialog
        open={open}
        onClose={onClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default DialogComponent;
