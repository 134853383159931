import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { PropTypes } from "prop-types";
import { fetchWrapper } from "../../services/api";

const DialogReason = ({
  open,
  handleClose,
  contractId,
  action,
  handleAccept,
  handleReject,
}) => {
  const [reason, setReason] = useState("");
  const token = localStorage.getItem("token");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (action == "Aceptar") {
        await handleAccept(contractId, reason);
      }

      if (action == "Rechazar") {
        await handleReject(contractId, reason);
      }
    } catch (error) {
    } finally {
      setReason("");
      handleClose();
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>{action} contrato</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Por favor, indique su motivo para aprobar o rechazar el contrato.
          </DialogContentText>
          <TextareaAutosize
            id="reason"
            placeholder="Escriba su motivo aquí (máximo 500 palabras)"
            maxRows={10}
            minRows={7}
            style={{ width: "100%", marginBottom: "16px", resize: "vertical" }}
            value={reason}
            onChange={handleReasonChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Enviar
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

DialogReason.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  contractId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

export default DialogReason;
