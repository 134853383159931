import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { fetchWrapper } from "../services/api";
import Viewer from "./pdf/Viewer";

const settings = ["Ver PDF", "Eliminar PDF"];

function PDFViewer({
  attached_file,
  rowId,
  socketRef,
  contractPhaseDescription,
}) {
  const [anchorElPdf, setAnchorElPdf] = useState(null);
  const [pdf, setPdf] = useState(null);

  const handleOpenPdfMenu = (event) => {
    setAnchorElPdf(event.currentTarget);
  };

  const handleClosePdfMenu = (selectedSetting) => {
    if (selectedSetting === "Ver PDF") {
      openPDF();
    } else if (selectedSetting === "Eliminar PDF") {
      deletePDF();
    }
    setAnchorElPdf(null);
  };

  const deletePDF = async () => {
    const formData = new FormData();
    formData.append("file", attached_file);

    fetchWrapper
      .delete(`/delete_contract/${rowId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: formData,
      })
      .then(() => {
        if (socketRef.current) {
          socketRef.current.emit("message", "Contrato actualizado.");
        }
      })
      .catch((error) => {
        console.error("Error al eliminar el Pdf:", error);
      });
  };

  const openPDF = async () => {
    fetchWrapper
      .get(`/serve_pdf?file_path=${attached_file}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        setPdf(response.data);
      })
      .catch((error) => {
        console.error("Error al abrir el PDF:", error);
      });
  };

  return (
    <Box>
      {pdf && (
        <Viewer
          open={pdf !== null}
          onClose={() => setPdf(null)}
          pdf={pdf}
          attached_file={attached_file}
        />
      )}
      <IconButton sx={{ p: 0 }} onClick={handleOpenPdfMenu}>
        <MoreVertIcon className="show-icon" />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElPdf}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElPdf)}
        onClose={handleClosePdfMenu}
      >
        {settings.map((setting) => {
          if (
            (setting === "Eliminar PDF" &&
              !localStorage.getItem("roles").includes("Legal")) ||
            contractPhaseDescription === "Finalizado"
          ) {
            return null;
          }
          return (
            <MenuItem key={setting} onClick={() => handleClosePdfMenu(setting)}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default PDFViewer;
