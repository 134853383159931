import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { Document, Page } from "react-pdf";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Viewer = ({ open, onClose, pdf, attached_file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  const downloadPDF = () => {
    const url = window.URL.createObjectURL(new Blob([pdf]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", attached_file);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1);
  const goToNextPage = () =>
    setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages);

  const handleZoomChange = (next) => {
    setScale((prev) => (next ? prev + 0.5 : prev - 0.5));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="pdf-viewer-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="pdf-viewer-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">PDF Viewer</Typography>
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={goToPrevPage} disabled={pageNumber === 1}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1">
              Página {pageNumber} de {numPages}
            </Typography>
            <IconButton
              onClick={goToNextPage}
              disabled={pageNumber === numPages}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              scale={scale}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" gap="15px">
          <IconButton onClick={() => handleZoomChange(false)}>
            <ZoomOut />
          </IconButton>
          <IconButton onClick={() => handleZoomChange(true)}>
            <ZoomIn />
          </IconButton>
        </Box>
        <Box display="flex" gap="15px">
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={downloadPDF}
            download
          >
            Descargar
          </Button>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cerrar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

Viewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pdf: PropTypes.string.isRequired,
};

export default Viewer;
