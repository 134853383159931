export const columns = [
  {id: 0},
  { id: "applicant", label: "Solicitante", align: "center" },
  { id: "contract_title", label: "Tipo de contrato", align: "center" },
  {
    id: 1,
    label: "Área",
    align: "center",
  },
  {
    id: 2,
    label: "Tipo de contrato",
    align: "center",
  },
  {
    id: 3,
    label: "Justificación de contrato",
    align: "center",
  },
  {
    id: 4,
    label: "Fase Actual",
    align: "center",
  },
  {
    id: 5,
    label: "Fecha de Creación",
    align: "center",
  },
  {
    id: 6,
    label: "Última actualización",
    align: "center",
  },
];
