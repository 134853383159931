import { configureStore } from '@reduxjs/toolkit';
import {
  modificationFormReducer,
  generalReducer,
  terminationFormReducer,
  transferFormReducer,
  representationFormReducer,
  employmentFormReducer,
  generalFormReducer,
} from './slices';

export const store = configureStore({
  reducer: {
    modificationForm: modificationFormReducer,
    general: generalReducer,
    terminationForm: terminationFormReducer,
    transferForm: transferFormReducer,
    representationForm: representationFormReducer,
    employmentForm: employmentFormReducer,
    inputsForm: generalFormReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
