import React, { useState, useEffect, useRef } from "react";
import Login from "./components/Login";
import "./assets/styles/styles.sass";
import Navbar from "./components/Navbar";
import ContractsTable from "./components/ContractsTable";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import { SignatureWindow } from "./components/SignatureWindow";
import MyRequests from "./components/myRequests";
import ContractDetails from "./components/ContractDetails";
import Toast from "./components/toast/Toast";
import { Provider } from "react-redux";
import { store } from "./store";
import { PrivateRoute } from "./utils";
import { urlBase } from "./services/api";
import NotFound from "./components/NotFound";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const theme = createTheme({
  palette: {
    primary: {
      main: "#21cb35",
    },
    secondary: {
      main: "#0062cc",

    },
  },
});

const socketConfig = {
  autoConnect: true,
  reconnection: true,
  transports: ["websocket"],
};

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [roles, setRoles] = useState(localStorage.getItem("roles"));
  const [currentUser, setUser] = useState(localStorage.getItem("currentUser"));
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState(null);

  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const socketRef = useRef(null);

  const handleCloseToast = () => {
    setToastData(null);
    setShowToast(false);
  };

  const handleShowToast = (title, message, type) => {
    setToastData({ title, message, type });
    setShowToast(true);
  };

  const handleLogin = ({ token, roles, currentUser }) => {
    localStorage.setItem("token", token);
    localStorage.setItem("roles", roles);
    localStorage.setItem("currentUser", currentUser);
    setToken(token);
    setRoles(roles);
    setUser(currentUser);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    setToken(null);
    setRoles(null);
  };

  useEffect(() => {
    const sse = new EventSource(
      `https://supplies.bravos-fcjuarez.com/events/?chanel=updates`,
      {
        withCredentials: false,
      }
    );

    sse.onmessage = (event) => {
      const dataEvent = JSON.parse(event.data);
      handleShowToast("Actualización", dataEvent.text, dataEvent.type);
      setLastUpdate(dataEvent);
    };
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div>
          {showToast && (
            <Toast
              title={toastData.title}
              message={toastData.message}
              type={toastData.type}
              closeToast={handleCloseToast}
            />
          )}
          <Routes>
            <Route
              element={
                <PrivateRoute token={token} handleLogout={handleLogout} />
              }
            >
              <Route
                path="/"
                element={
                  <div>
                    <div className="container">
                      <Navbar onLogout={handleLogout} />
                      <ContractsTable
                        socketRef={socketRef}
                        lastUpdate={lastUpdate}
                        setLastUpdate={setLastUpdate}
                        currentUser={currentUser}
                        onShowToast={handleShowToast}
                        onClearToast={handleCloseToast}
                      />
                    </div>
                  </div>
                }
              />
              {/* <Route path="/signature" element={<SignatureWindow />} /> */}
              <Route
                path="/contracts/:id/details"
                element={<ContractDetails token={token} />}
              />
              <Route
                path="/my-requests"
                element={
                  <div className="container">
                    <Navbar onLogout={handleLogout} />
                    <MyRequests
                      onShowToast={handleShowToast}
                      onClearToast={handleCloseToast}
                    />
                  </div>
                }
              />
            </Route>
            {/* public routes */}
            <Route
              path="/login"
              element={
                <Login onLogin={handleLogin} onShowToast={handleShowToast} />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
