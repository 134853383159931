import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ReasonModal = ({
  open,
  handleClose,
  contractId,
  action,
  handleAccept,
  handleReject,
}) => {
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (action == "Aceptar") {
        await handleAccept(contractId, reason);
      }

      if (action == "Rechazar") {
        await handleReject(contractId, reason);
      }
    } catch (error) {
    } finally {
      setReason("");
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        elevation: 0,
        sx: {
          border: "solid 5px gray",
        },
      }}
      slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
    >
      <DialogTitle>{action} contrato</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor, indique su motivo para aprobar o rechazar el contrato.
        </DialogContentText>
        <TextareaAutosize
          id="reason"
          placeholder="Escriba su motivo aquí (máximo 500 palabras)"
          maxRows={10}
          minRows={7}
          style={{ width: "100%", marginBottom: "16px", resize: "vertical" }}
          value={reason}
          onChange={handleReasonChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Enviar
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReasonModal;
