export const columsDetails = {
  contracts_employmentcontract: {
    id: { title: "identificador", type: "string", format: "" },
    contract_type: { title: "tipo de contrato", type: "string", format: "" },
    directed_to: { title: "dirigido a", type: "string", format: "" },
    category: { title: "categoría", type: "string", format: "" },
    request_date: {
      title: "fecha de solicitud",
      type: "date",
      format: "YYYY-MM-DD",
    },
    full_name: { title: "nombre completo", type: "string", format: "" },
    nationality: { title: "nacionalidad", type: "string", format: "" },
    date_of_birth: {
      title: "fecha de nacimiento",
      type: "date",
      format: "YYYY-MM-DD",
    },
    address: { title: "dirección", type: "string", format: "" },
    marital_status: { title: "estado civil", type: "string", format: "" },
    email: { title: "correo electrónico", type: "email", format: "email" },
    free_agent_or_transfer: {
      title: "agente libre o transferencia",
      type: "string",
      format: "",
    },
    relocation: { title: "reubicación", type: "string", format: "" },
    contract_duration: {
      title: "duración del contrato",
      type: "string",
      format: "",
    },
    annual_salary: { title: "salario anual", type: "number", format: "" },
    first_payment_date: {
      title: "fecha del primer pago",
      type: "date",
      format: "YYYY-MM-DD",
    },
    last_payment_date: {
      title: "fecha del último pago",
      type: "date",
      format: "YYYY-MM-DD",
    },
    payment_frequency: {
      title: "frecuencia de pago",
      type: "string",
      format: "",
    },
    sports_bonuses: {
      title: "bonificaciones deportivas",
      type: "string",
      format: "",
    },
    other_benefits: { title: "otros beneficios", type: "string", format: "" },
    player_termination_clause: {
      title: "cláusula de rescisión del jugador",
      type: "string",
      format: "",
    },
    club_termination_clause: {
      title: "cláusula de rescisión del club",
      type: "string",
      format: "",
    },
    jurisdiction: { title: "jurisdicción", type: "string", format: "" },
    signing_date: {
      title: "fecha de firma",
      type: "date",
      format: "YYYY-MM-DD",
    },
    contract_id: { title: "ID del contrato", type: "string", format: "" },
    position: { title: "posición", type: "string", format: "" },
  },
  contracts_modificationagreement: {
    id: { title: "identificador", type: "string", format: "" },
    agreement_type: { title: "tipo de acuerdo", type: "string", format: "" },
    category: { title: "categoría", type: "string", format: "" },
    request_date: {
      title: "fecha de solicitud",
      type: "date",
      format: "YYYY-MM-DD",
    },
    full_name: { title: "nombre completo", type: "string", format: "" },
    modifications_to_make: {
      title: "modificaciones a realizar",
      type: "string",
      format: "",
    },
    contract_id: { title: "ID del contrato", type: "string", format: "" },
  },
  contracts_representationcontract: {
    id: { title: "identificador", type: "string", format: "" },
    full_name: { title: "nombre completo", type: "string", format: "" },
    nationality: { title: "nacionalidad", type: "string", format: "" },
    address: { title: "dirección", type: "string", format: "" },
    rfc_or_tax_id: {
      title: "RFC o identificación fiscal",
      type: "string",
      format: "",
    },
    passport_number: {
      title: "número de pasaporte",
      type: "string",
      format: "",
    },
    fifa_registration_number: {
      title: "número de registro FIFA",
      type: "string",
      format: "",
    },
    consideration: { title: "contraprestación", type: "string", format: "" },
    other_conditions: {
      title: "other_conditions",
      type: "string",
      format: "",
    },
    bank_details: { title: "detalles bancarios", type: "string", format: "" },
    jurisdiction: { title: "jurisdicción", type: "string", format: "" },
    contract_id: { title: "ID del contrato", type: "string", format: "" },
  },
  contracts_termination: {
    id: { title: "identificador", type: "string", format: "" },
    termination_type: {
      title: "tipo de terminación",
      type: "string",
      format: "",
    },
    category: { title: "categoría", type: "string", format: "" },
    request_date: {
      title: "fecha de solicitud",
      type: "date",
      format: "YYYY-MM-DD",
    },
    full_name: { title: "nombre completo", type: "string", format: "" },
    termination_amount: {
      title: "monto de terminación",
      type: "number",
      format: "",
    },
    payment_date: {
      title: "fecha de pago",
      type: "date",
      format: "YYYY-MM-DD",
    },
    signing_date: {
      title: "fecha de firma",
      type: "date",
      format: "YYYY-MM-DD",
    },
    notes: { title: "notas", type: "string", format: "" },
    contract_id: { title: "ID del contrato", type: "string", format: "" },
  },
  contracts_transfercontract: {
    id: { title: "identificador", type: "string", format: "" },
    club_name: { title: "nombre del club", type: "string", format: "" },
    club_representative: {
      title: "representante del club",
      type: "string",
      format: "",
    },
    email: { title: "correo electrónico", type: "email", format: "email" },
    transfer_type: {
      title: "tipo de transferencia",
      type: "string",
      format: "",
    },
    validity: { title: "validez", type: "string", format: "" },
    free_or_cost: { title: "libre o con costo", type: "string", format: "" },
    purchase_option: {
      title: "opción de compra",
      type: "string",
      format: "",
    },
    solidarity_withholding: {
      title: "retención por solidaridad",
      type: "string",
      format: "",
    },
    other_conditions: {
      title: "otras condiciones",
      type: "string",
      format: "",
    },
    contract_id: { title: "ID del contrato", type: "string", format: "" },
  },
};
