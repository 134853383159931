import axios from "axios";
export const urlBase = "https://supplies.bravos-fcjuarez.com";
// export const urlBase = "http://localhost:8000";

export const client = axios.create({
  baseURL: urlBase,
});


client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      window.location.href = '/login'; // Redirige manualmente
    }
    return Promise.reject(error);
  }
);

const request = (method) => async (url, options) => {
  try {
    const response = await client({
      method,
      url,
      ...options,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
};
