import { Fragment, useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Collapse,
  Typography,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ContractHistoryComponent from "../ContractHistory";
import moment from "moment";
import DialogDetails from "../dialog/dialogDetails";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Row = ({ row, onShowToast, contractSF }) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const strategyClass = {
    Rechazado: "phase-red phase-center",
    Solicitado: "phase-turquoise phase-center",
    "Revisión Legal": "phase-gray phase-center",
    "Esperando Contrato": "phase-mustard phase-center",
    "Revisión Administrativa": "phase-gold phase-center",
    "Revision Subpresidencia": "phase-blue phase-center",
    "Revisión Presidencia": "phase-pink phase-center",
    "Esperando Contrato Firmado": "phase_sg phase-center",
    Finalizado: "phase-green phase-center",
  };

  const descriptionDetails = (row) => {
    if (contractSF.includes(row.id)) {
      return (
        <Link
          component="button"
          onClick={() => setOpenDialog(true)}
          underline="hover"
        >
          {row.type_description}
        </Link>
      );
    }

    return row.type_description;
  };

  const phaseDescription = (row) => {
    const lenghtDescription =
      (row.phase_description?.length ? row.phase_description.length : 10) + 10;

    if (row.phase_id === 1) {
      return (
        <>
          <span style={{ color: "red" }}>*</span> Esperando corrección.
        </>
      );
    }
    return (
      <Box
        height={lenghtDescription}
        className={strategyClass[row.phase_description]}
      >
        {row.phase_description}
      </Box>
    );
  };

  const formatDate = (date) => {
    return moment
      .utc(date)
      .tz("America/Mexico_City")
      .locale("es")
      .format("DD [de] MMMM [de] YYYY [a las] HH:mm");
  };

  return (
    <Fragment>
      <DialogDetails
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Detalles"
        contractId={row.id}
      />
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.applicant}
        </StyledTableCell>
        <StyledTableCell align="center">{row.contract_title}</StyledTableCell>
        <StyledTableCell align="center">{row.area}</StyledTableCell>
        <StyledTableCell align="center">
          {descriptionDetails(row)}
        </StyledTableCell>
        <StyledTableCell align="center">{row.request_reason}</StyledTableCell>
        <StyledTableCell align="center">
          {phaseDescription(row)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(row.created_at)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(row.updated_at)}
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historial de movimientos
              </Typography>
              {/* Historial */}
              <ContractHistoryComponent
                contractId={row.id}
                onShowToast={onShowToast}
              />
              {/* Termina el historial */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Row;
