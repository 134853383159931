import * as React from "react";
import { Box, Typography, Button } from "@mui/material";

const NotFound = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        background: "linear-gradient(45deg, #000000 70%, #00C900 100%, #000000)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        fontFamily: "Roboto, sans-serif",
        textAlign: "center",
      }}
    >
      <Typography variant="h1" sx={{ mb: 2, fontWeight: "bold" }}>
        404
      </Typography>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Página no encontrada
      </Typography>
      <Typography variant="body1" sx={{ mb: 5 }}>
        Lo sentimos, pero la página que buscas no existe.
      </Typography>
      <Button
        variant="contained"
        href="/"
        sx={{
          backgroundColor: "#00C900",
          color: "#000",
          "&:hover": {
            backgroundColor: "#009900",
          },
        }}
      >
        Volver al inicio
      </Button>
    </Box>
  );
};

export default NotFound;
