import { Fragment, useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Collapse,
  Typography,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PDFViewer from "../PDF_Viewer";
import moment from "moment";
import ReasonModal from "../ReasonModal";
import ContractHistoryComponent from "../ContractHistory";
import DialogDetails from "../dialog/dialogDetails";
import ContractsForm from "../ContractsForm";
import DialogReason from "../dialog/dialogReason";

function Row(props) {
  const {
    row,
    onShowToast,
    handleOpenReasonsModal,
    currentUser,
    editingContractId,
    setLastUpdate,
    handleCloseModal,
    openModal,
    handleOpenModal,
    socketRef,
    handleFileChange,
    contractsWithSupplementaryFiles,
  } = props;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const token = localStorage.getItem("token");

  const strategyClass = {
    Rechazado: "phase-red phase-center",
    Solicitado: "phase-turquoise phase-center",
    "Revisión Legal": "phase-gray phase-center",
    "Esperando Contrato": "phase-mustard phase-center",
    "Revisión Administrativa": "phase-gold phase-center",
    "Revision Subpresidencia": "phase-blue phase-center",
    "Revisión Presidencia": "phase-pink phase-center",
    "Esperando Contrato Firmado": "phase_sg phase-center",
    Finalizado: "phase-green phase-center",
  };

  return (
    <Fragment>
      <DialogDetails
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Detalles"
        contractId={row.id}
      />
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.applicant}</TableCell>
        <TableCell align="center">{row.contract_title}</TableCell>
        <TableCell align="center">{row.area}</TableCell>
        {contractsWithSupplementaryFiles.includes(row.id) ? (
          <TableCell align="center">
            <Link
              component="button"
              onClick={() => setOpenDialog(true)}
              underline="hover"
              color={"blue"}
            >
              {row.type_description}
            </Link>
          </TableCell>
        ) : (
          <TableCell align="center">{row.type_description}</TableCell>
        )}
        <Tooltip title={<h2>{row.request_reason}</h2>}>
          <TableCell
            align="center"
            style={{ whiteSpace: "normal", overflowWrap: "break-word" }}
          >
            {row.request_reason.length <= 19
              ? row.request_reason
              : row.request_reason.slice(0, 19) + "..."}
          </TableCell>
        </Tooltip>

        <TableCell align="center" sx={{ fontSize: ".8em" }} width={150}>
          <div>
            {row.phase_id == 1 && (
              <div>
                <span style={{ color: "red" }}>*</span> Esperando corrección.
              </div>
            )}
            <div className={strategyClass[row.phase_description]}>
              {row.phase_id == 8
                ? "Esperando C. Firmado"
                : row.phase_description}
            </div>
          </div>
        </TableCell>
        <TableCell align="center">
          {moment
            .utc(row.created_at)
            .tz("America/Mexico_City")
            .locale("es")
            .format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
        </TableCell>
        <TableCell align="center">
          {moment
            .utc(row.updated_at)
            .tz("America/Mexico_City")
            .locale("es")
            .format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
        </TableCell>
        <TableCell className="actions" align="center">
          <div style={{ display: "flex" }}>
            {currentUser != "Francisco Sanchez" && row.phase_id > 3 ? (
              <Fragment>
                <button
                  onClick={() => {
                    if (
                      !localStorage.getItem("roles").includes("Legal") &&
                      !localStorage.getItem("roles").includes("Presidencia")
                    ) {
                      return;
                    }
                    document.getElementById(`file-upload[${row.id}]`).click();
                  }}
                  disabled={
                    !localStorage.getItem("roles").includes("Legal") &&
                    !localStorage.getItem("roles").includes("Presidencia") &&
                    row.phase_id !== 4 &&
                    row.phase_id !== 8
                  }
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <PictureAsPdfIcon
                    className={`pdf-icon ${
                      row.attached_file || row.administration_attached_file
                        ? "red-icon"
                        : "grey-icon"
                    }`}
                  />
                </button>
                <input
                  id={`file-upload[${row.id}]`}
                  name={"file"}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(event, row.id)}
                />
                {row.attached_file ? (
                  <PDFViewer
                    attached_file={row.attached_file}
                    rowId={row.id}
                    socketRef={socketRef}
                    contractPhaseDescription={row.description}
                  />
                ) : (
                  row.administration_attached_file && (
                    <PDFViewer
                      attached_file={row.administration_attached_file}
                      rowId={row.id}
                      socketRef={socketRef}
                      contractPhaseDescription={row.description}
                    />
                  )
                )}
              </Fragment>
            ) : null}

            {[
              "Francisco Sanchez",
              "Alejandra Torres",
              "Mirele Perez",
              "Jesus Vazquez",
            ].includes(currentUser) &&
            ["Rechazado", "Solicitado"].includes(row.phase_description) &&
            [1, 2].includes(parseInt(row.phase_id)) ? (
              <Fragment>
                <Tooltip title="editar">
                  <IconButton
                    onClick={() => handleOpenModal(row.id)}
                    style={{ background: "#007bff" }}
                    sx={{ color: "#fff" }}
                    size="small"
                    variant="contained"
                  >
                    <EditIcon />
                    {/* editar */}
                  </IconButton>
                </Tooltip>

                <ContractsForm
                  isOpen={openModal && editingContractId === row.id}
                  handleClose={handleCloseModal}
                  token={token}
                  contractInformation={row}
                  onShowToast={onShowToast}
                  setLastUpdate={setLastUpdate}
                  editingContractId={editingContractId}
                />
              </Fragment>
            ) : null}

            {![
              "Francisco Sanchez",
              "Alejandra Torres",
              "Mirele Perez",
              "Jesus Vazquez",
            ].includes(currentUser) &&
            [2, 3, 5, 6, 7].includes(parseInt(row.phase_id)) ? (
              <Tooltip title="Aceptar">
                <IconButton
                  sx={{ color: "#fff" }}
                  className="action-buttons"
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleOpenReasonsModal(row.id, "Aceptar")}
                >
                  <ThumbUpIcon color="primary" />
                  {/* aceptar */}
                </IconButton>
              </Tooltip>
            ) : null}

            {(parseInt(row.phase_id) === 2 &&
              ![
                "Francisco Sanchez",
                "Alejandra Torres",
                "Mirele Perez",
                "Jesus Vazquez",
              ].includes(currentUser) &&
              ![1, 4].includes(parseInt(row.phase_id))) ||
            [3, 5, 6, 7].includes(parseInt(row.phase_id)) ? (
              <Tooltip title="Rechazar">
                <IconButton
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={() => handleOpenReasonsModal(row.id, "Rechazar")}
                >
                  <ThumbDownIcon />
                  {/* rechazar */}
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: "#ebe9eb" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historial de movimientos
              </Typography>
              {/* Historial */}
              <ContractHistoryComponent
                contractId={row.id}
                onShowToast={onShowToast}
              />
              {/* Termina el historial */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default Row;
