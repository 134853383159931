import * as React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import ReasonModal from "./ReasonModal";
import PDFViewer from "./PDF_Viewer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContractsForm from "./ContractsForm";

const ContractsTableMobile = ({
  rows,
  handleFileChange,
  socketRef,
  anchorElPdf,
  handleClosePdfMenu,
  currentUser,
  handleOpenModal,
  openModal,
  editingContractId,
  handleCloseModal,
  token,
  handleOpenReasonsModal,
  openReasonsModal,
  handleCloseReasonsModal,
  action,
  handleAccept,
  handleReject,
}) => {
  const settings = ["Adjuntar PDF"];
  const phaseClasses = {
    Rechazado: "phase-red phase-center",
    Solicitado: "phase-turquoise phase-center",
    "Revisión Legal": "phase-gray phase-center",
    "Esperando Contrato": "phase-mustard phase-center",
    "Revisión Administrativa": "phase-gold phase-center",
    "Revision Subpresidencia": "phase-blue phase-center",
    "Revisión Presidencia": "phase-pink phase-center",
    Finalizado: "phase-green phase-center",
  };
  return (
    <div>
      {rows.length > 0 ? (
        rows.map((row) => (
          <Card
            key={row.id}
            sx={{ maxWidth: 345 }}
            style={{ marginBottom: "4vh" }}
          >
            <CardActionArea>
              <CardContent style={{ background: "#fdfcfc" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{ marginBottom: "3vh" }}
                  >
                    {row.contract_title}
                  </Typography>
                  <Typography style={{ textAlign: "right" }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      {/* Inicio */}
                      {localStorage.getItem("roles").includes("Legal") &&
                        !localStorage
                          .getItem("roles")
                          .includes("Administrativo") && (
                          <React.Fragment>
                            <PictureAsPdfIcon
                              onClick={() => {
                                document
                                  .getElementById(`file-upload[${row.id}]`)
                                  .click();
                              }}
                              className={`pdf-icon ${
                                row.attached_file ? "red-icon" : "grey-icon"
                              }`}
                            />
                            <input
                              id={`file-upload[${row.id}]`}
                              name={"file"}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileChange(event, row.id)
                              }
                            />
                            {row.attached_file && (
                              <PDFViewer
                                attached_file={row.attached_file}
                                rowId={row.id}
                                socketRef={socketRef}
                                contractPhaseDescription={row.description}
                              />
                            )}
                          </React.Fragment>
                        )}
                      {/* Fin */}
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElPdf}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElPdf)}
                        onClose={handleClosePdfMenu}
                      >
                        {settings.map((setting) => (
                          <MenuItem
                            key={setting}
                            onClick={() => handleClosePdfMenu(setting)}
                          >
                            <Typography textAlign="center">
                              {setting}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Typography>
                </div>
                <Typography variant="body2">{row.request_reason}</Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "25px",
                  }}
                >
                  {row.phase_id == 1 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span> Esperando
                      corrección.
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                    className={phaseClasses[row.phase_description] || ""}
                  >
                    {row.phase_description}
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(currentUser == "Francisco Sanchez" &&
                row.phase_description == "Rechazado") ||
                (currentUser == "Alejandra Torres" &&
                  row.phase_description == "Rechazado" &&
                  currentUser == "Mirele Perez" &&
                  row.phase_description == "Rechazado" && (
                    <React.Fragment>
                      <Button
                        onClick={() => handleOpenModal(row.id)}
                        style={{ background: "#007bff" }}
                        sx={{ color: "#fff" }}
                        size="small"
                        variant="contained"
                      >
                        Editar
                      </Button>
                      <ContractsForm
                        isOpen={openModal && editingContractId === row.id}
                        handleClose={handleCloseModal}
                        token={token}
                        contractInformation={row}
                      />
                    </React.Fragment>
                  ))}
              {[
                "Francisco Sanchez",
                "Alejandra Torres",
                "Mirele Perez",
                "Jesus Vazquez",
              ].includes(currentUser) &&
              [1, 2, 3, 5, 6, 7].includes(parseInt(row.phase_id)) ? (
                <Button
                  sx={{ color: "#fff" }}
                  className="action-buttons"
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleOpenReasonsModal(row.id, "Aceptar")}
                >
                  Aceptar
                </Button>
              ) : null}
              {(parseInt(row.phase_id) === 2 &&
                ![
                  "Francisco Sanchez",
                  "Alejandra Torres",
                  "Mirele Perez",
                  "Jesus Vazquez",
                ].includes(currentUser) &&
                parseInt(row.phase_id) !== 1 &&
                parseInt(row.phase_id) !== 4) ||
              parseInt(row.phase_id) === 3 ||
              parseInt(row.phase_id) === 5 ||
              parseInt(row.phase_id) === 6 ||
              parseInt(row.phase_id) == 7 ? (
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={() => handleOpenReasonsModal(row.id, "Rechazar")}
                >
                  Rechazar
                </Button>
              ) : null}
            </CardActions>
            <ReasonModal
              open={openReasonsModal}
              handleClose={handleCloseReasonsModal}
              contract={row}
              action={action}
              handleAccept={handleAccept}
              handleReject={handleReject}
            />
          </Card>
        ))
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px 0",
            color: "#333333",
          }}
        >
          No se encontraron registros.
        </div>
      )}
    </div>
  );
};

ContractsTableMobile.propTypes = {
  rows: PropTypes.array.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  socketRef: PropTypes.object.isRequired,
  anchorElPdf: PropTypes.object.isRequired,
  handleClosePdfMenu: PropTypes.func.isRequired,
  currentUser: PropTypes.string.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  editingContractId: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  handleOpenReasonsModal: PropTypes.func.isRequired,
  openReasonsModal: PropTypes.bool.isRequired,
  handleCloseReasonsModal: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

export default ContractsTableMobile;
