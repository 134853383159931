import React, { useEffect, useState, useRef } from 'react';
import { Chip, Select, MenuItem, Box, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchWrapper } from '../../services/api';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs'

const ChipContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '8px',
    padding: '15px'
});

const StyledChip = styled(Chip)(({ position }) => ({
    borderRadius: 0,
    marginRight: '-4px',
    '&:first-of-type': {
        borderRadius: '16px 0 0 16px',
    },
    '&:last-of-type': {
        borderRadius: '0 16px 16px 0',
        marginRight: 0,
    },
}));

const FilterChips = ({ setRows }) => {
    const token = localStorage.getItem("token");
    const [filtersDb, setfiltersDb] = useState([{}]);
    const [filters, setFilters] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [cleared, setCleared] = useState(false);
    const timeoutRef = useRef(null);
    const columnsFiltered = [{ "label": "Solicitante", "id": "applicants" }, { label: "Área", "id": "areas" }, { "label": "Tipo de Contrato", "id": "types" }, { "label": "Fase Actual", "id": "phases" }]

    const handleGetFilters = async () => {
        fetchWrapper.get("/filters/", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setfiltersDb(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        handleGetFilters();
    }, [])

    const handlerContracts = async (fil) => {
        let basePath = '/contracts_per_phase';

        if (fil != []) {
            basePath += "?";
        }

        const groupedFilters = fil.reduce((acc, { filter, value }) => {
            if (!acc[filter]) {
                acc[filter] = [];
            }
            acc[filter].push(value);
            return acc;
        }, {});

        const queryParams = new URLSearchParams();
        for (const [key, values] of Object.entries(groupedFilters)) {
            queryParams.append(key, values.join(","))
        }

        const queryString = queryParams.toString();

        fetchWrapper
            .get(basePath + queryString, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setRows(response.data);
            })
            .catch((error) => {
                console.error("Error al obtener datos de la API:", error);
            });
    }

    const handleAddFilter = (event, label) => {
        const value = event.target.value;

        console.log(!filters.filter((f) => f.value != value))

        if (filters.filter((f) => f.value != value)) {
            setFilters([...filters, { filter: label, value: value }]);
            handlerContracts([...filters, { filter: label, value: value }]);
        }
    };

    const handleDeleteFilter = (filter) => {
        setFilters(filters.filter((f) => f.value !== filter.value));

        handlerContracts(filters.filter((f) => f.value !== filter.value));
    };

    const handlerDatesStart = (date) => {
        if (date == null) {
            handlerContracts(filters.filter((f) => f.filter != "startDate"))
            setFilters(filters.filter((f) => f.filter != "startDate"));
            return;
        }
        const result = dayjs(date).format('YYYY-MM-DD')
        console.log("start", result)
        setFilters([...filters, { filter: "startDate", value: result }]);
        handlerContracts([...filters, { filter: "startDate", value: result }])
    }

    const handlerDateEnd = (date) => {
        if (date == null) {
            handlerContracts(filters.filter((f) => f.filter != "endDate"))
            setFilters(filters.filter((f) => f.filter != "endDate"));
            return;
        }

        const result = dayjs(date).format('YYYY-MM-DD')
        console.log("end",result)
        setFilters([...filters, { filter: "endDate", value: result }]);
        handlerContracts([...filters, { filter: "endDate", value: result }])
    }

    const handleChangeTextDes = (event) => {
        const { value } = event.target;
        setInputValue(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current); // Limpia el timeout anterior
        }

        timeoutRef.current = setTimeout(() => {
            // Actualiza después de 300ms
            if (value == "") {
                handlerContracts(filters.filter((f) => f.filter != "contract_title"))
                return;
            }
            handlerContracts([...filters, { filter: "contract_title", value: value }])
        }, 300);
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex" justifyContent="space-between" flexDirection="row" gap={1}>
                    <Tooltip title="busqueda por patron (se buscara por la palabra ingresada)">
                        <TextField id="outlined-basic" label="Título de contrato" variant="outlined" size='small' onChange={handleChangeTextDes} value={inputValue} />
                    </Tooltip>
                    {columnsFiltered.map((col, index) => (
                        <Select
                            key={`a${index}`}
                            onChange={(event) => handleAddFilter(event, col.id)}
                            value={col.label}
                            displayEmpty
                            inputProps={{ "aria-label": "Seleccione una fase" }}
                            size='small'
                        >
                            <MenuItem value={col.label} disabled>{col.label}</MenuItem>
                            {

                                filtersDb[col.id] && Array.isArray(filtersDb[col.id]) ? (
                                    filtersDb[col.id].map((option) => (
                                        <MenuItem key={option.id} value={option.description}>
                                            {option.description}
                                        </MenuItem>
                                    ))
                                ) : "sin opciones"
                            }
                        </Select>
                    ))}
                </Box>
                <Box display="flex" justifyContent="space-between" flexDirection="row" gap={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha de inicio"
                            onChange={handlerDatesStart}
                            slotProps={{ textField: { size: 'small' }, field: { clearable: true, onClear: () => handlerDatesStart(null) } }}
                            name="startDate"
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="Fecha termina"
                            onChange={handlerDateEnd}
                            slotProps={{ textField: { size: 'small' }, field: { clearable: true, onClear: () => handlerDateEnd(null) } }}
                            name="endDate"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <ChipContainer>
                {filters.map((filter, index) => (
                    <StyledChip
                        key={filter.value}
                        label={filter.value}
                        onDelete={() => handleDeleteFilter(filter)}
                        color="success"
                        variant="outlined"
                        position={index}
                    />
                ))}
            </ChipContainer>
        </Box>

    );
};

export default FilterChips;
