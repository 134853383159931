import React, { createContext, useState, useEffect, useContext } from "react";
import { Modal, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Crear el contexto
const InactivityContext = createContext();

export const InactivityProvider = ({ children, handleLogout }) => {
  const [inactive, setInactive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [warningTimer, setWarningTimer] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();

  const warningTime = 3600000; // 60 minutos

  useEffect(() => {
    // Función que resetea el temporizador
    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      if (warningTimer) clearTimeout(warningTimer);

      setTimer(
        setTimeout(() => {
          setInactive(true);
          setModalOpen(true);
          setCountdown(10);

          setWarningTimer(setTimeout(logout, 100000));  // 10 segundos

          const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(countdownInterval);
              }
              return prevCountdown - 1;
            });
          }, 1000);
        }, warningTime)
      );
    };

    const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    resetTimer();

    return () => {
      clearTimeout(timer);
      clearTimeout(warningTimer);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, []);

  const logout = () => {
    clearTimeout(timer);
    clearTimeout(warningTimer);
    setInactive(false);
    setModalOpen(false);
    handleLogout();
    navigate("/login");
  };

  const resetInactivity = () => {
    setInactive(false);
    setModalOpen(false);
    clearTimeout(timer);
    clearTimeout(warningTimer);
  };

  return (
    <InactivityContext.Provider value={{ inactive, resetInactivity, logout }}>
      {children}

      <Modal open={modalOpen} onClose={() => {}}>
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            textAlign: "center",
            margin: "auto",
            marginTop: "20vh",
            width: "300px",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Inactividad detectada
          </Typography>
          <Typography variant="body1" gutterBottom>
            Será desconectado en {countdown} segundos debido a la inactividad.
          </Typography>
          <Button variant="contained" onClick={resetInactivity} sx={{ mt: 2 }}>
            Continuar usando
          </Button>
        </div>
      </Modal>
    </InactivityContext.Provider>
  );
};

export const useInactivity = () => useContext(InactivityContext);
