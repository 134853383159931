import React, { useEffect, useState } from "react";
import { TextField, TextareaAutosize } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  setEmploymentPosition,
  setEmploymentContractType,
  setEmploymentDirectedTo,
  setEmploymentCategory,
  setEmploymentRequestDate,
  setEmploymentFullName,
  setEmploymentNationality,
  setEmploymentDateOfBirth,
  setEmploymentAddress,
  setEmploymentMaritalStatus,
  setEmploymentEmail,
  setEmploymentFreeAgentOrTransfer,
  setEmploymentRelocation,
  setEmploymentContractDuration,
  setEmploymentAnnualSalary,
  setEmploymentFirstPaymentDate,
  setEmploymentLastPaymentDate,
  setEmploymentPaymentFrequency,
  setEmploymentSportsBonuses,
  setEmploymentOtherBenefits,
  setEmploymentPlayerTerminationClause,
  setEmploymentClubTerminationClause,
  setEmploymentJurisdiction,
  setEmploymentSigningDate,
  setSupplementaryFileType,
} from "../slices";
import { useFormik } from "formik";
import { setDisableFormSubmit } from "../slices";

function EmploymentForm() {
  const dispatch = useDispatch();

  const {
    employmentPosition,
    employmentContractType,
    employmentDirectedTo,
    employmentCategory,
    employmentRequestDate,
    employmentFullName,
    employmentNationality,
    employmentDateOfBirth,
    employmentAddress,
    employmentMaritalStatus,
    employmentEmail,
    employmentFreeAgentOrTransfer,
    employmentRelocation,
    employmentContractDuration,
    employmentAnnualSalary,
    employmentFirstPaymentDate,
    employmentLastPaymentDate,
    employmentPaymentFrequency,
    employmentSportsBonuses,
    employmentOtherBenefits,
    employmentPlayerTerminationClause,
    employmentClubTerminationClause,
    employmentJurisdiction,
    employmentSigningDate,
  } = useSelector((state) => state.employmentForm);

  const [firstTimeOpen, setFirstTimeOpen] = useState(true);

  const formik = useFormik({
    initialValues: {
      employmentFullName: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.employmentFullName) {
        errors.employmentFullName = "Debe ingresar un Nombre Completo";
      }
      if (!values.employmentPosition) {
        errors.employmentPosition = "Debe ingresar un Puesto de Trabajo";
      }
      if (!values.employmentNationality) {
        errors.employmentNationality = "Debe ingresar una Nacionalidad";
      }
      if (!values.employmentDateOfBirth) {
        errors.employmentDateOfBirth = "Debe ingresar una Fecha de Nacimiento";
      }
      if (!values.employmentAddress) {
        errors.employmentAddress = "Debe ingresar una Dirección";
      }
      if (!values.employmentMaritalStatus) {
        errors.employmentMaritalStatus = "Debe ingresar un Estado Civil";
      }
      if (!values.employmentEmail) {
        errors.employmentEmail = "Debe ingresar un Email";
      } else if (!/^\S+@\S+\.\S+$/.test(values.employmentEmail)) {
        errors.employmentEmail = "Estructura de Email inválida";
      }
      if (!values.employmentFreeAgentOrTransfer) {
        errors.employmentFreeAgentOrTransfer =
          "Debe indicar si es Agente Libre o Transferencia";
      }
      if (!values.employmentRelocation) {
        errors.employmentRelocation = "Debe ingresar Traslado";
      }
      if (!values.employmentContractDuration) {
        errors.employmentContractDuration =
          "Debe ingresar la Vigencia del Contrato";
      }
      if (!values.employmentAnnualSalary) {
        errors.employmentAnnualSalary = "Debe ingresar el Sueldo Anual";
      }
      if (!values.employmentFirstPaymentDate) {
        errors.employmentFirstPaymentDate =
          "Debe ingresar la Fecha de primer pago ";
      }
      if (!values.employmentLastPaymentDate) {
        errors.employmentLastPaymentDate =
          "Debe ingresar la Fecha de último pago ";
      }
      if (!values.employmentSportsBonuses) {
        errors.employmentSportsBonuses =
          "Debe ingresar si existen Bonos Deportivos";
      }
      if (!values.employmentOtherBenefits) {
        errors.employmentOtherBenefits =
          "Debe ingresar si existen Otras Prestaciones";
      }
      if (!values.employmentPlayerTerminationClause) {
        errors.employmentPlayerTerminationClause =
          "Debe indicar si existe Cláusula de rescisión de jugador";
      }
      if (!values.employmentClubTerminationClause) {
        errors.employmentClubTerminationClause =
          "Debe indicar si existe Cláusula de rescisión de club";
      }
      if (!values.employmentSigningDate) {
        errors.employmentSigningDate = "Debe indicar la Fecha de Firma";
      }
      if (!values.employmentCategory) {
        errors.employmentCategory = "Debe indicar la Categoría";
      }
      if (!values.employmentRequestDate) {
        errors.employmentRequestDate = "Debe indicar la Fecha de Solicitud";
      }

      return errors;
    },
    onSubmit: (values) => {
      const formData = {
        employmentFullName: employmentFullName,
        employmentPosition: employmentPosition,
        employmentNationality: employmentNationality,
        employmentDateOfBirth: employmentDateOfBirth,
        employmentAddress: employmentAddress,
        employmentMaritalStatus: employmentMaritalStatus,
        employmentFreeAgentOrTransfer: employmentFreeAgentOrTransfer,
        employmentRelocation: employmentRelocation,
        employmentContractDuration: employmentContractDuration,
        employmentAnnualSalary: employmentAnnualSalary,
        employmentFirstPaymentDate: employmentFirstPaymentDate,
        employmentLastPaymentDate: employmentLastPaymentDate,
        employmentSportsBonuses: employmentSportsBonuses,
        employmentOtherBenefits: employmentOtherBenefits,
        employmentPlayerTerminationClause: employmentPlayerTerminationClause,
        employmentClubTerminationClause: employmentClubTerminationClause,
        employmentSigningDate: employmentSigningDate,
        employmentCategory: employmentCategory,
        employmentRequestDate: employmentRequestDate,
      };
    },
  });

  const dispatchAll = () => {
    dispatch(setEmploymentFullName(formik.values.employmentFullName));
    dispatch(setEmploymentPosition(formik.values.employmentPosition));
    dispatch(setEmploymentNationality(formik.values.employmentNationality));
    dispatch(setEmploymentDateOfBirth(formik.values.employmentDateOfBirth));
    dispatch(setEmploymentAddress(formik.values.employmentAddress));
    dispatch(setEmploymentMaritalStatus(formik.values.employmentMaritalStatus));
    dispatch(setEmploymentEmail(formik.values.employmentEmail));
    dispatch(
      setEmploymentFreeAgentOrTransfer(
        formik.values.employmentFreeAgentOrTransfer
      )
    );
    dispatch(setEmploymentRelocation(formik.values.employmentRelocation));
    dispatch(
      setEmploymentContractDuration(formik.values.employmentContractDuration)
    );
    dispatch(setEmploymentAnnualSalary(formik.values.employmentAnnualSalary));
    dispatch(
      setEmploymentFirstPaymentDate(formik.values.employmentFirstPaymentDate)
    );
    dispatch(
      setEmploymentLastPaymentDate(formik.values.employmentLastPaymentDate)
    );
    dispatch(setEmploymentSportsBonuses(formik.values.employmentSportsBonuses));
    dispatch(setEmploymentOtherBenefits(formik.values.employmentOtherBenefits));
    dispatch(
      setEmploymentPlayerTerminationClause(
        formik.values.employmentPlayerTerminationClause
      )
    );
    dispatch(
      setEmploymentClubTerminationClause(
        formik.values.employmentClubTerminationClause
      )
    );
    dispatch(setEmploymentSigningDate(formik.values.employmentSigningDate));
    dispatch(setEmploymentCategory(formik.values.employmentCategory));
    dispatch(setEmploymentRequestDate(formik.values.employmentRequestDate));
  };

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0 && !firstTimeOpen) {
      dispatch(setDisableFormSubmit(false));
    } else {
      dispatch(setDisableFormSubmit(true));
    }

    if (formik.isValid) {
      dispatchAll();
    }

    setFirstTimeOpen(false);
  }, [formik.errors]);

  useEffect(() => {
    dispatch(setSupplementaryFileType("employment contract"));
  }, []);

  return (
    <section id="employment-form">
      <div style={{ textAlign: "center" }}>
        <h2>Formato de trabajo</h2>
      </div>
      <div style={{ textAlign: "center", width: "100%" }}>
        <h4>Datos Generales Del Jugador O Cuerpo Técnico</h4>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          {formik.errors.employmentFullName && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentFullName}
            </div>
          )}
          <TextField
            label="Nombre completo"
            variant="outlined"
            value={formik.values.employmentFullName}
            onChange={formik.handleChange}
            name="employmentFullName"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentPosition && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentPosition}
            </div>
          )}
          <TextField
            label="Puesto"
            variant="outlined"
            value={formik.values.employmentPosition}
            onChange={formik.handleChange}
            name="employmentPosition"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentNationality && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentNationality}
            </div>
          )}
          <TextField
            label="Nacionalidad"
            variant="outlined"
            value={formik.values.employmentNationality}
            onChange={formik.handleChange}
            name="employmentNationality"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentDateOfBirth && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentDateOfBirth}
            </div>
          )}
          <div style={{ marginBottom: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de nacimiento"
                value={formik.values.employmentDateOfBirth}
                onChange={(date) =>
                  formik.setFieldValue("employmentDateOfBirth", date)
                }
                name="employmentDateOfBirth"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {formik.errors.employmentAddress && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentAddress}
            </div>
          )}
          <TextField
            label="Dirección"
            variant="outlined"
            value={formik.values.employmentAddress}
            onChange={formik.handleChange}
            name="employmentAddress"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentMaritalStatus && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentMaritalStatus}
            </div>
          )}
          <TextField
            label="Estado Civil"
            variant="outlined"
            value={formik.values.employmentMaritalStatus}
            onChange={formik.handleChange}
            name="employmentMaritalStatus"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentEmail && (
            <div style={{ color: "red" }}>{formik.errors.employmentEmail}</div>
          )}
          <TextField
            label="Email"
            variant="outlined"
            value={formik.values.employmentEmail}
            onChange={formik.handleChange}
            name="employmentEmail"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentFreeAgentOrTransfer && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentFreeAgentOrTransfer}
            </div>
          )}
          <TextField
            label="Agente libre o transferencia"
            variant="outlined"
            value={formik.values.employmentFreeAgentOrTransfer}
            onChange={formik.handleChange}
            name="employmentFreeAgentOrTransfer"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentRelocation && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentRelocation}
            </div>
          )}
          <TextareaAutosize
            aria-label="Traslado"
            placeholder="Traslado"
            multiline
            rows={4}
            value={formik.values.employmentRelocation}
            onChange={formik.handleChange}
            name="employmentRelocation"
            maxLength={200}
            style={{ width: "100%", marginBottom: "1rem" }}
          />

          <div style={{ textAlign: "center", width: "100%" }}>
            <h4>Condiciones Del Contrato De Trabajo</h4>
          </div>

          {formik.errors.employmentContractDuration && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentContractDuration}
            </div>
          )}
          <div style={{ marginBottom: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Vigencia del contrato"
                value={formik.values.employmentContractDuration}
                onChange={(date) =>
                  formik.setFieldValue("employmentContractDuration", date)
                }
                name="employmentContractDuration"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {formik.errors.employmentAnnualSalary && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentAnnualSalary}
            </div>
          )}
          <TextField
            label="Sueldo anual"
            variant="outlined"
            value={formik.values.employmentAnnualSalary}
            onChange={formik.handleChange}
            name="employmentAnnualSalary"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentFirstPaymentDate && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentFirstPaymentDate}
            </div>
          )}
          <div style={{ marginBottom: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de primer pago"
                value={formik.values.employmentFirstPaymentDate}
                onChange={(date) =>
                  formik.setFieldValue("employmentFirstPaymentDate", date)
                }
                name="employmentFirstPaymentDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {formik.errors.employmentLastPaymentDate && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentLastPaymentDate}
            </div>
          )}
          <div style={{ marginBottom: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de último pago"
                value={formik.values.employmentLastPaymentDate}
                onChange={(date) =>
                  formik.setFieldValue("employmentLastPaymentDate", date)
                }
                name="employmentLastPaymentDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <FormControl style={{ marginBottom: "15px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Modalidad de pago
            </FormLabel>
            <RadioGroup
              style={{ marginLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Mensual"
              name="radio-buttons-group"
              value={employmentPaymentFrequency}
              onChange={(e) =>
                dispatch(setEmploymentPaymentFrequency(e.target.value))
              }
            >
              <FormControlLabel
                value="Mensual"
                control={<Radio />}
                label="Mensual"
              />
              <FormControlLabel
                value="Quincenal"
                control={<Radio />}
                label="Quincenal"
              />
            </RadioGroup>
          </FormControl>
          {formik.errors.employmentSportsBonuses && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentSportsBonuses}
            </div>
          )}
          <TextareaAutosize
            aria-label="Bonos deportivos"
            placeholder="Bonos deportivos"
            multiline
            rows={4}
            value={formik.values.employmentSportsBonuses}
            onChange={formik.handleChange}
            name="employmentSportsBonuses"
            maxLength={200}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
          {formik.errors.employmentOtherBenefits && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentOtherBenefits}
            </div>
          )}
          <TextareaAutosize
            aria-label="Otras prestaciones"
            placeholder="Otras prestaciones"
            multiline
            rows={4}
            value={formik.values.employmentOtherBenefits}
            onChange={formik.handleChange}
            name="employmentOtherBenefits"
            maxLength={200}
            style={{ width: "100%", marginBottom: "1rem" }}
          />
          {formik.errors.employmentPlayerTerminationClause && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentPlayerTerminationClause}
            </div>
          )}
          <TextField
            label="Cláusula de rescisión de jugador"
            variant="outlined"
            value={formik.values.employmentPlayerTerminationClause}
            onChange={formik.handleChange}
            name="employmentPlayerTerminationClause"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentClubTerminationClause && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentClubTerminationClause}
            </div>
          )}
          <TextField
            label="Cláusula de rescisión de club"
            variant="outlined"
            value={formik.values.employmentClubTerminationClause}
            onChange={formik.handleChange}
            name="employmentClubTerminationClause"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          <FormControl style={{ marginBottom: "15px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Jurisdicción
            </FormLabel>
            <RadioGroup
              style={{ marginLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="FIFA"
              name="radio-buttons-group"
              value={employmentJurisdiction}
              onChange={(e) =>
                dispatch(setEmploymentJurisdiction(e.target.value))
              }
            >
              <FormControlLabel value="FIFA" control={<Radio />} label="FIFA" />
              <FormControlLabel value="CCRC" control={<Radio />} label="CCRC" />
            </RadioGroup>
          </FormControl>
          {formik.errors.employmentSigningDate && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentSigningDate}
            </div>
          )}
          <div style={{ marginBottom: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de firma"
                value={formik.values.employmentSigningDate}
                onChange={(date) =>
                  formik.setFieldValue("employmentSigningDate", date)
                }
                name="employmentSigningDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ textAlign: "center", width: "100%" }}>
            <h4>Informacion Extra</h4>
          </div>
          <FormControl style={{ marginBottom: "15px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Tipo de contrato
            </FormLabel>
            <RadioGroup
              style={{ marginLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Contrato"
              name="radio-buttons-group"
              value={employmentContractType}
              onChange={(e) =>
                dispatch(setEmploymentContractType(e.target.value))
              }
            >
              <FormControlLabel
                value="Contrato"
                control={<Radio />}
                label="Contrato"
              />
              <FormControlLabel
                value="Convenio"
                control={<Radio />}
                label="Convenio"
              />
              <FormControlLabel
                value="Adenda"
                control={<Radio />}
                label="Adenda"
              />
            </RadioGroup>
          </FormControl>
          <div>
            <FormControl style={{ marginBottom: "15px" }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Jugador o Cuerpo Técnico
              </FormLabel>
              <RadioGroup
                style={{ marginLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Contrato"
                name="radio-buttons-group"
                value={employmentDirectedTo}
                onChange={(e) =>
                  dispatch(setEmploymentDirectedTo(e.target.value))
                }
              >
                <FormControlLabel
                  value="Jugador"
                  control={<Radio />}
                  label="Jugador"
                />
                <FormControlLabel
                  value="Cuerpo Técnico"
                  control={<Radio />}
                  label="Cuerpo Técnico"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {formik.errors.employmentCategory && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentCategory}
            </div>
          )}
          <TextField
            label="Categoría"
            variant="outlined"
            value={formik.values.employmentCategory}
            onChange={formik.handleChange}
            name="employmentCategory"
            style={{ width: "100%", marginBottom: "15px" }}
          />
          {formik.errors.employmentRequestDate && (
            <div style={{ color: "red" }}>
              {formik.errors.employmentRequestDate}
            </div>
          )}
          <div style={{ marginBottom: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de solicitud"
                value={formik.values.employmentRequestDate}
                onChange={(date) =>
                  formik.setFieldValue("employmentRequestDate", date)
                }
                name="employmentRequestDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </form>
      </div>
    </section>
  );
}

export default EmploymentForm;
