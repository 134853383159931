import DialogComponent from ".";
import { useEffect, useState } from "react";
import { fetchWrapper } from "../../services/api";
import { PropTypes } from "prop-types";
import { Stack, Skeleton } from "@mui/material";
import { columsDetails } from "./config";

const DialogDetails = ({ open, onClose, title, contractId }) => {
  const [details, setDetails] = useState({});
  const [supplementaryFileType, setSupplementaryFileType] = useState("");
  const token = localStorage.getItem("token");

  const handlerGetDetails = async () => {
    fetchWrapper
      .get(`/contracts/${contractId}/supplementary_file_details/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDetails(response.data.data[0]);
        setSupplementaryFileType(response.data.supplementary_file_type);
      });
  };

  const buildDetailsContent = () => {
    const detailsContent = [];
    const columns = columsDetails[supplementaryFileType];
    for (const [key, value] of Object.entries(details)) {
      if (key === "id") {
        continue;
      }

      detailsContent.push(
        <span key={key}>
          <b>{columns[key].title.toUpperCase()}</b>
          {": "}
          {value}
          <br />
        </span>
      );
    }
    return detailsContent;
  };

  useEffect(() => {
    if (open) {
      handlerGetDetails();
    }
  }, [open]);

  return (
    <DialogComponent open={open} onClose={onClose} title={title}>
      <Stack
        spacing={1}
        direction="row"
        useFlexGap
        alignItems={"center"}
        sx={{ flexWrap: "wrap" }}
      >
        {details && Object.keys(details).length > 0 ? (
          buildDetailsContent()
        ) : (
          <Skeleton variant="rounded" width={"50vh"} height={60} />
        )}
      </Stack>
    </DialogComponent>
  );
};

DialogDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  contractId: PropTypes.number,
};

export default DialogDetails;
